















































































































































































































































































































































































































































import WNavDropdown from '@/components/applayout/WNavDropdown.vue';
import { Component, Watch } from 'vue-property-decorator';
import { Nav, NavItem } from '@/models/Nav';
import ConferenceService from '@/service/ConferenceService';
import { Conference } from '@/models/Conference';
import { EventBus, Events } from '@/EventBus';
import Base from '@/views/Base';
import { NationalNumber } from '@/models/api';
import { BookingListItem } from '@/models/Booking';
import BookingService from '@/service/BookingService';
import { Account } from '@/models/Account';
import WDropdownItem from '@/components/applayout/WDropdownItem.vue';
import AuthenticationService, { AuthenticationStorage } from '@/service/AuthenticationService';
import LogoIcon from '@/assets/logo.svg';
import WNewsDropdown from '@/components/applayout/WNewsDropdown.vue';
import { setNavigation } from '@/utils/nav';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: {
		WNavDropdown,
		WDropdownItem,
		LogoIcon,
		WNewsDropdown
	}
})
export default class AppLayout extends Base {
	nationalNumbers: NationalNumber[] = [];

	navOpen = false;

	conferences: Conference[] = [];

	nav: Nav = setNavigation(this);

	conferencesInterval!: number;

	ccpUrl: string | null = null;
	ccpWindow: Window | null = null;

  fullHeaderHeight = 140;

	get conferencesExist(): boolean {
		return this.conferences.length > 0;
	}

	get loading(): boolean {
		return this.$store.getters.networkIndicator;
	}

	get isHome(): boolean {
		return this.$route.name === 'Home';
	}

	get conferencesUpdate(): boolean {
		return this.$store.getters.conferencesUpdate;
	}

	get username(): string {
		return this.$store.getters.username;
	}

	get operatorMode(): boolean {
		return this.username.includes('|');
	}

	get authentication(): string {
		return this.$store.getters.authentication;
	}

	@Watch('authentication')
	authenticationChanged(authentication: AuthenticationStorage): void {
		console.log('authentication changed', authentication);
		if (this.ccpWindow && this.ccpUrl) {
			this.ccpWindow.postMessage(
				'authentication=' + JSON.stringify(authentication),
				this.ccpUrl
			);
		}
	}

	@Watch('conferencesUpdate')
	conferenceUpdateChanged(newValue: boolean): void {
		if (newValue && this.isUser()) {
			this.getConferences();
		}
	}

	@Watch('user')
	userChanged(newValue: Account, oldValue: Account): void {
		if (oldValue) {
			if (oldValue.role !== 'USER') {
				this.userInit();
			}
			this.nav.itemsRight[0].title = this.$store.getters.username;
		}
	}

	@Watch('theme', { immediate: true })
	setThemeSettings(): void {
		const presentationItem = this.nav.itemsLeft.find(
			(item) => item.ref === 'presentationNavItem'
		);
		if (presentationItem && this.theme.weblink?.dataDcpUrl)
			presentationItem.to = this.theme.weblink.dataDcpUrl;
	}

	mounted(): void {
		let url: string | undefined = process.env.VUE_APP_CCP_URL;
		if (url === undefined) {
			console.log(
				"No environment variable 'VUE_APP_CCP_URL' found. Use 'http://localhost:8081"
			);
			url = 'http://192.168.41.75:8081';
		}
		this.ccpUrl = url;

		setTimeout(() => {
			if (this.isUser()) {
				this.userInit();
			}
		}, 1000);

    EventBus.$on(Events.HeaderHeightChanged, (height: number)=>{
      console.log("Height: " + height);
      this.fullHeaderHeight = height;
    });

	}

	private userInit(): void {
		this.getConferences();
		this.getNationalNumbers();

		this.conferencesInterval = window.setInterval(() => {
			this.getConferences();
		}, 5000);

		// Events
		EventBus.$on(Events.OpenCCP, (bookingId: string) => {
			const id = this.conferences.find(
				(conf) => conf.bookingId === bookingId
			)?.id;
			if (id) this.openCCP(id);
		});
		EventBus.$on(Events.ConferenceDetail, (booking: BookingListItem) => {
			switch (booking.type) {
				case 'room':
				case 'scheduled':
					this.$router.push('/conference/scheduled/' + booking.id);
					break;
				case 'past':
					this.$router.push('/conference/past/' + booking.id);
					break;
				case 'running':
					this.$router.push('/conference/running/' + booking.id);
					break;
			}
		});
		EventBus.$on(Events.AuthenticationCleared, () => {
			console.log('authentication cleared');
			if (this.ccpWindow && this.ccpUrl) {
				this.ccpWindow.postMessage('authenticationCleared', this.ccpUrl);
			}
		});
	}

	beforeDestroy(): void {
		this.userDestroy();
    EventBus.$off(Events.HeaderHeightChanged);
	}

	private userDestroy(): void {
		EventBus.$off(Events.OpenCCP);
		EventBus.$off(Events.EndConference);
		EventBus.$off(Events.ConferenceDetail);
		clearInterval(this.conferencesInterval);
		this.conferences = [];
		this.$store.commit('setConferences', []);
	}

	private getConferences(): void {
		ConferenceService.getConferences()
			.then((data) => {
				this.conferences = data;
				this.$store.commit('setConferences', data);
			})
			.then(() => {
				if (this.conferencesUpdate)
					this.$store.commit('setConferencesUpdate', false);
			})
			.catch(() => {
				if (this.$router.currentRoute.path !== '/login') {
					console.error(this.$t('common.serverError'));
				}
			});
	}

	private getNationalNumbers(): void {
		BookingService.getNationalNumbers().then(
			(numbers) => (this.nationalNumbers = numbers)
		);
		BookingService.getNationalNumbersConfig().then((config) =>
			this.$store.commit('setNumbersCount', config.mainPhonenumbersCount)
		);
	}

	openCCP(id: string): void {
		if (this.ccpUrl === null) return;
    const language = this.$i18n.locale;
		this.ccpWindow = window.open(
			this.ccpUrl + (id ? `/?id=${id}&openFromClient=true&language=${language}` : ''),
			'ccp',
			'width=640,height=700,left=500,top=100'
		);
		const interval = setInterval(() => {
			console.log('post connecting', this.ccpUrl);
			if (this.ccpWindow && this.ccpUrl)
				this.ccpWindow.postMessage('connecting', this.ccpUrl);
		}, 1000);

		window.addEventListener('message', (event) => {
			if (event.origin !== this.ccpUrl) return;
			if (event.data === 'connected') {
				console.log('Conference connected');
				clearInterval(interval);

				console.log('post token');
				if (this.ccpWindow) {
					this.ccpWindow.postMessage(
						'authentication=' +
							JSON.stringify(this.$store.getters.authentication),
						this.ccpUrl
					);
					this.$store.commit('setLastActionTimestamp');
				}
			}
			if (event.data === 'disconnected') {
				console.log('Conference disconnected');
			}
		});
	}

	isVisible(item: NavItem): boolean {
		const auth = item.authenticated
			? this.$store.getters.isAuthenticated
			: !this.$store.getters.isAuthenticated;
		if (item && item.role) {
			switch (item.role) {
				case 'user':
					return this.isUser() && auth;
				case 'user_creates':
					return (
						this.isUser() && this.hasPermission('CUSTOMER_CREATE_USER') && auth
					);
				case 'admin':
					return this.isAdmin() && auth;
				case 'sadmin':
					return this.isSAdmin() && auth;
				case 'any_admin':
					return (this.isAdmin() || this.isSAdmin()) && auth;
        case 'w2':
          return (this.hasPermission("W2_ACCESS"))
      }
		}
		return auth;
	}

	logOutAs(): void {
		const usernames = this.username.split('|');
		AuthenticationService.stopImpersonate({
			parentUsername: usernames[0],
			childUsername: usernames[1]
		}).then(() => {
			if (localStorage.prevAdmin === 'running') {
				this.$router.push('/admin/console/running');
				localStorage.removeItem('prevAdmin');
			} else {
				this.$router.push('/admin/users');
			}
			this.userDestroy();
		});
	}

	logout(): void {
		AuthenticationService.logout();
		this.$router.push({ name: 'Login' });
	}

	formatTimeDifference(str: string): string {
		let diff = Date.now() - parseInt(str);
		let diffHrs = Math.floor((diff % 86400000) / 3600000)
			.toString()
			.padStart(2, '0');
		let diffMins = Math.floor(((diff % 86400000) % 3600000) / 60000)
			.toString()
			.padStart(2, '0');
		let diffSecs = Math.floor((((diff % 86400000) % 3600000) % 60000) / 1000)
			.toString()
			.padStart(2, '0');
		return (diffHrs !== '00' ? diffHrs + ':' : '') + diffMins + ':' + diffSecs;
	}
}
